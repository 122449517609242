import React from 'react';
import './SectionTv.css';

// Importe a imagem
import tvImage from '../assets/images/tv.png'; // Atualize o caminho conforme a estrutura do seu projeto

function SectionTv() {
    return (
        <div className="section-tv-boxes" id="tvhd" >
            <div className="column-wrapper">
                <div className="left-tv-column">
                    <h2 className="section-title">A melhor internet,<br></br>
                        com a melhor TV ao
                        vivo</h2>
                    <p className="section-subtitle">Os clientes da Millenium desfrutam de uma plataforma abrangente e repleta de recursos que proporciona acesso a uma vasta gama de entretenimento. A nossa plataforma oferece a transmissão de canais ao vivo, uma extensa biblioteca de filmes, uma coleção diversificada de séries, emocionantes shows e uma ampla variedade de conteúdos para atender a todos os gostos e preferências.</p>
                </div>
                <div className="right-tv-column">
                    {/* Insira a imagem aqui */}
                    <img src={tvImage} alt="Imagem da TV" width="500" />
                </div>
            </div>
        </div>
    );
}

export default SectionTv;
