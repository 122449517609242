import React from 'react';
import './SectionAutoAjuda.css';

import viafatura from '../assets/icons/autoatendimento/2viafatura.png';
import centralcliente from '../assets/icons/autoatendimento/centralcliente.png';
import consultarplanos from '../assets/icons/autoatendimento/consultarplanos.png';
import whatsapp from '../assets/icons/autoatendimento/whatsapp.png';

function SectionAutoAjuda() {

    // Função para manipular o clique no botão
    const FaturaButton = () => {
        // Redirecionar para a URL desejada
        window.location.href = 'https://ixc.millenium.souuni.com/central_assinante_web/login';
    };
    // Função para manipular o clique no botão
    const WhatsappContato = () => {
        // Redirecionar para a URL desejada
        window.location.href = 'https://wa.me//5569999293966?text= Oi, vim pelo site, preciso de ajuda!';
    };
    return (
        <div className="section-auto-body" id="atendimento">
            <div className="section-auto-boxes">
                <div className="column-wrapper">
                    <div className="left-auto-column">
                        <h2 className="section-title">Autoatendimento para clientes</h2>
                        <p className="section-subtitle">Entrar em contato com o suporte da Millenium é uma tarefa simples e eficiente.
                            Você pode fazer sua escolha dentre as opções disponíveis abaixo e, em seguida, clicar na alternativa selecionada.</p>
                    </div>
                    <div className="right-auto-column">
                        <div className="icon-row">
                            <div className="icon-card">
                                <img className="icon-image" src={viafatura} alt="Ícone 1" />
                                <h3>2ª via de Fatura</h3>
                                <button className="ajuda-button" onClick={FaturaButton}>Acessar</button>
                            </div>
                            <div className="icon-card">
                                <img className="icon-image" src={centralcliente} alt="Ícone 2" />
                                <h3>Central Assinante</h3>
                                <button className="ajuda-button" onClick={FaturaButton}>Acessar</button>
                            </div>
                        </div>
                        <div className="icon-row">
                            <div className="icon-card">
                                <img className="icon-image" src={consultarplanos} alt="Ícone 3" />
                                <h3>Consultar Planos</h3>
                                <button className="ajuda-button" onClick={WhatsappContato}>Acessar</button>
                            </div>
                            <div className="icon-card">
                                <img className="icon-image" src={whatsapp} alt="Ícone 4" />
                                <h3>Whatsapp</h3>
                                <button className="ajuda-button" onClick={WhatsappContato}>Acessar</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default SectionAutoAjuda;
